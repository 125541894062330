import { useEffect } from 'react';
import { DEFAULT_SCHEDULE_ONCE_CALENDAR, VIDEO_TYPES_MAP } from '../constants';
import { useGlobalState } from '../state/globalStateContext';

export function getVideoTypeByUrl(url: string): keyof typeof VIDEO_TYPES_MAP {
  const type = Object.values(VIDEO_TYPES_MAP).find(({ formatRegex }) =>
    url.trim().match(formatRegex),
  )?.type;
  if (!type) {
    throw new Error(
      `Got video url "${url}" that didn't match any known type: ${Object.keys(VIDEO_TYPES_MAP).join(
        ', ',
      )}`,
    );
  }
  return type;
}

export function useStoreScheduleOnceCalendarOnLoad(): void {
  const { setScheduleOnceCalendar } = useGlobalState();
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const scheduleOnceCalendar = urlSearchParams.get('socalendar');

    if (scheduleOnceCalendar) {
      // store in global state
      setScheduleOnceCalendar(scheduleOnceCalendar);
      if (window.localStorage) {
        // store in local storage
        window.localStorage.setItem('socalendar', scheduleOnceCalendar);
      }
    } else if (window.localStorage) {
      // get from local storage to global state
      setScheduleOnceCalendar(
        window.localStorage.getItem('socalendar') || DEFAULT_SCHEDULE_ONCE_CALENDAR,
      );
    }
  }, []);
}

function getDaySuffix(day: number): string {
  if (day > 3 && day < 21) {
    return 'th';
  } else if (day % 10 === 1) {
    return 'st';
  } else if (day % 10 === 2) {
    return 'nd';
  } else if (day % 10 === 3) {
    return 'rd';
  } else {
    return 'th';
  }
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  const [month, day, year] = formattedDate.split(' ');

  const dayNumber = parseInt(day.replace(',', ''), 10);
  const daySuffix = getDaySuffix(dayNumber);

  return `${month} ${dayNumber}${daySuffix} ${year}`;
}
