import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React from 'react';

import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import { SanityImageType } from '../../graphql-fragments/SanityImage';
import PreviewLoadingScreen from '../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../preview/previewUtils';
import { clsx, replaceNewLinesWithBr } from '../../utils/utils';
import { ModuleBackgroundColor } from '../ModulesContent';
import ButtonLink from './ButtonLink';
import * as styles from './Hero.module.scss';
import Image from './Image';
import ModuleLayout from './ModuleLayout';
import RatingStars from './RatingStars';
import Slider from './Slider';

const ELEMENT_ANIMATION_DURATION = 1.5;

export type HeroProps = {
  pageColor: ModuleBackgroundColor;
  superTitle?: string;
  title: string;
  subtitle?: string;
  text?: string;
  button?: ButtonLinkType;
  showClientBrandsLogos?: boolean;
  reviews?: Array<string>;
} & (
  | {
      image: SanityImageType;
      textAndImageAlignment?: 'imageBelowText';
      textAlignment: 'left' | 'center';
      imageFitOption: 'contain' | 'cover';
    }
  | {
      image: SanityImageType;
      textAndImageAlignment?: 'imageBesideText';
      textAlignment?: never;
      imageFitOption: 'contain' | 'cover';
    }
);

interface QueryData {
  allSanityClientBrand: {
    nodes: Array<{
      darkLogo: SanityImageType;
      whiteLogo: SanityImageType;
      title: string;
    }>;
  };
}

const Hero = ({
  superTitle,
  title,
  subtitle,
  text,
  button,
  image,
  pageColor,
  showClientBrandsLogos,
  textAndImageAlignment,
  textAlignment,
  imageFitOption,
  reviews,
}: HeroProps): React.ReactElement => {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityClientBrand(sort: { orderRank: ASC }) {
        nodes {
          title
          darkLogo {
            ...SanityImage
          }
          whiteLogo {
            ...SanityImage
          }
        }
      }
    }
  `);

  const groqQuery = groq`{
    "allSanityClientBrand": {
      "nodes": *[_type == "clientBrand"] | order(orderRank asc) {
        ...
      }
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const { nodes: clientBrands } = data.allSanityClientBrand;

  return (
    <ModuleLayout
      className={styles.container}
      contentClassName={clsx(
        styles.contentContainer,

        showClientBrandsLogos && styles.withMinHeight,
      )}
      childrenClassName={clsx(
        image && textAndImageAlignment === 'imageBelowText' && styles.imageBelowText,
        image &&
          textAndImageAlignment === 'imageBelowText' &&
          textAlignment === 'center' &&
          styles.centeredText,
        image && textAndImageAlignment === 'imageBesideText' && styles.imageBesideText,
      )}
      currentModuleBgColor={pageColor}
    >
      <div className={styles.content}>
        <div className={styles.textContainer}>
          {superTitle && (
            <div className={styles.superTitleContainer}>
              <p className={styles.superTitle}>{superTitle}</p>
            </div>
          )}
          <h1 className={styles.title}>{replaceNewLinesWithBr(title)}</h1>
          {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
          {text && <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>}
          {button && (
            <ButtonLink to={button} className={styles.button}>
              {button.title}
            </ButtonLink>
          )}

          {reviews && reviews.length > 0 && (
            <div className={styles.reviewsContainer}>
              <div className={styles.divider} />
              <div className={styles.reviewsWrapper}>
                {reviews.map((review, i) => (
                  <div className={styles.reviewContainer} key={i}>
                    <RatingStars rating={5} fillColor="#FFAC0C" starsSize={16} gapSize={5} />
                    <p className={styles.review}>"{review}"</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {showClientBrandsLogos && clientBrands && clientBrands.length > 0 && (
          <div className={styles.brandsContainer}>
            <div className={styles.divider}></div>
            <span className={styles.brandsContainerTitle}>
              Trusted By The World's Most Loved Brands
            </span>
            <div className={styles.sliderWrapper}>
              <Slider
                animationDuration={ELEMENT_ANIMATION_DURATION * clientBrands.length + 1}
                stopAnimationOnLargerScreens
                className={styles.slider}
              >
                <div className={styles.logosContainer}>
                  {clientBrands.map((brand, i) => (
                    <div className={styles.logoContainer} key={i}>
                      <Image
                        alt={brand.title}
                        title={brand.title}
                        image={pageColor === 'dark' ? brand.whiteLogo : brand.darkLogo}
                      />
                    </div>
                  ))}
                </div>
              </Slider>
            </div>
          </div>
        )}
      </div>
      {image && (
        <div
          className={clsx(
            styles.imageContainer,
            imageFitOption === 'contain' && styles.containedImage,
          )}
        >
          <Image
            image={image}
            cover={imageFitOption === 'cover'}
            width={1920}
            height={textAndImageAlignment === 'imageBesideText' ? 1080 : 685}
          />
        </div>
      )}
    </ModuleLayout>
  );
};

export default Hero;
