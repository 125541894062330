import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as styles from './hooks.module.scss';

import Button from '../components/ui/Button';
import ListPagination from '../components/ui/ListPagination';
import { SHOULD_RUN_GOOGLE_OPTIMIZE } from '../constants';
import { useGlobalState } from '../state/globalStateContext';
import { sortBy } from './nodash';

export function useActivateGoogleOptimize(): boolean {
  const [isHidden, setIsHidden] = useState(SHOULD_RUN_GOOGLE_OPTIMIZE);
  // Initialize google optimize experiment on 'optimize.activate'
  useEffect(() => {
    if (SHOULD_RUN_GOOGLE_OPTIMIZE) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'optimize.activate' });
      // Send analytics.activate event with delay to ensure that Google Optimize
      // has had time to run the experiment before analytics triggers
      setTimeout(() => {
        window.dataLayer!.push({ event: 'analytics.activate' });
      }, 100);
      setIsHidden(false);
    }
  }, []);
  return isHidden;
}

export function useStoreUTMParamsOnLoad(): void {
  const {
    setUtmSource,
    setUtmMedium,
    setUtmCampaign,
    setUtmTerm,
    setUtmContent,
    setUtmDevice,
    setUtmTarget,
  } = useGlobalState();
  useEffect(() => {
    const utmParamNamesToSetFunction = {
      utm_source: setUtmSource,
      utm_medium: setUtmMedium,
      utm_campaign: setUtmCampaign,
      utm_term: setUtmTerm,
      utm_content: setUtmContent,
      utm_device: setUtmDevice,
      utm_target: setUtmTarget,
    };
    const utmParamNames = Object.keys(utmParamNamesToSetFunction);

    const urlSearchParams = new URLSearchParams(window.location.search);

    const urlHasUtmParams =
      utmParamNames.map(utmParamName => urlSearchParams.get(utmParamName)).filter(Boolean).length >
      0;

    if (urlHasUtmParams) {
      for (const utmParamName of utmParamNames) {
        const utmParamValue = urlSearchParams.get(utmParamName);
        if (utmParamValue) {
          // store in global state
          utmParamNamesToSetFunction[utmParamName](utmParamValue);
          if (window.localStorage) {
            // store in local storage
            window.localStorage.setItem(utmParamName, utmParamValue);
          }
        } else if (window.localStorage) {
          // remove from local storage
          window.localStorage.removeItem(utmParamName);
        }
      }
    } else if (window.localStorage) {
      // get from local storage to global state
      setUtmSource(window.localStorage.getItem('utm_source'));
      setUtmMedium(window.localStorage.getItem('utm_medium'));
      setUtmCampaign(window.localStorage.getItem('utm_campaign'));
      setUtmTerm(window.localStorage.getItem('utm_term'));
      setUtmContent(window.localStorage.getItem('utm_content'));
      setUtmDevice(window.localStorage.getItem('utm_device'));
      setUtmTarget(window.localStorage.getItem('utm_target'));
    }
  }, []);
}

export function useStoreReferrerOnLoad(): void {
  const { setWebsiteReferrer } = useGlobalState();
  useEffect(() => {
    const websiteReferrer = document.referrer;
    const websiteReferrerHost = websiteReferrer ? new URL(websiteReferrer).host : null;
    const isReferrerCurrentWebsite = websiteReferrerHost === window.location.host;
    if (websiteReferrer && !isReferrerCurrentWebsite) {
      // store in global state
      setWebsiteReferrer(websiteReferrer);
      if (window.localStorage) {
        // store in local storage
        window.localStorage.setItem('website_referrer', websiteReferrer);
      }
    } else if (window.localStorage) {
      // get from local storage to global state
      setWebsiteReferrer(window.localStorage.getItem('website_referrer'));
    }
  }, []);
}

export interface TrackingData {
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
  utmTerm: string | null;
  utmContent: string | null;
  utmDevice: string | null;
  utmTarget: string | null;
  websiteReferrer: string | null;
}

export function useTrackingData(): TrackingData {
  const {
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
    utmDevice,
    utmTarget,
    websiteReferrer,
  } = useGlobalState();
  return {
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
    utmDevice,
    utmTarget,
    websiteReferrer,
  };
}

export function useSingleRunCallback(callBack: () => void): void {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
}

export interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

function getDimensionObject(node: HTMLElement): DimensionObject {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    // @ts-ignore
    top: 'x' in rect ? rect.x : rect.top,
    // @ts-ignore
    left: 'y' in rect ? rect.y : rect.left,
    // @ts-ignore
    x: 'x' in rect ? rect.x : rect.left,
    // @ts-ignore
    y: 'y' in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
  };
}
export interface UseDimensionsOptions {
  measureOnResize?: boolean;
  measureOnScroll?: boolean;
}

export function useDimensions(
  ref: React.MutableRefObject<HTMLElement | null>,
  { measureOnResize = true, measureOnScroll = true }: UseDimensionsOptions,
): DimensionObject | null {
  const [dimensions, setDimensions] = useState<DimensionObject | null>(null);
  useLayoutEffect(() => {
    const node = ref.current;
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)));
      measure();

      if (measureOnResize) {
        window.addEventListener('resize', measure);
      }
      if (measureOnScroll) {
        window.addEventListener('scroll', measure);
      }

      return () => {
        if (measureOnResize) {
          window.removeEventListener('resize', measure);
        }
        if (measureOnScroll) {
          window.removeEventListener('scroll', measure);
        }
      };
    }
    return;
  }, [ref.current]);

  return dimensions;
}

/**
 * Returns an object with the width and height of the window if it's being used in the browser,
 * otherwise returns an object with nulls
 */
export function useWindowDimensions() {
  const [dimensions, setDimensions] = useState({
    height: typeof window !== 'undefined' ? window.innerHeight : null,
    width: typeof window !== 'undefined' ? window.innerWidth : null,
  });
  useLayoutEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return dimensions;
}

export function useScrollY(): number | null {
  const [scrollY, setScrollY] = useState<number | null>(null);
  useLayoutEffect(() => {
    const getScrollY = () => window.requestAnimationFrame(() => setScrollY(window.scrollY));
    getScrollY();
    window.addEventListener('scroll', getScrollY);
    return () => window.removeEventListener('scroll', getScrollY);
  }, []);

  return scrollY;
}

export function useOnLoadEffect(effectFunction: () => void): boolean {
  const [hasRunOnLoadEffect, setHasRunOnLoadEffect] = useState(false);
  useEffect(() => {
    effectFunction();
    setHasRunOnLoadEffect(true);
  }, []);
  return hasRunOnLoadEffect;
}

export function usePagination<Item>(
  items: Array<Item>,
  itemsPerPage: number,
  containerRef?: React.RefObject<HTMLDivElement>,
): {
  currentPage: number;
  currentPageItems: Item[];
  loading: boolean;
  getPageUrlPart: () => readonly [string, string | null];
  resetPagination: () => void;
  renderPagination: () => JSX.Element;
} {
  const [currentPage, setCurrentPage] = useState(1);
  const [nCardsVisible, setNCardsVisible] = useState(itemsPerPage);
  const [loading, setLoading] = useState(false);

  const currentPageItems = items.slice(
    (currentPage - 1) * nCardsVisible,
    currentPage * nCardsVisible,
  );

  const hasRunOnLoadEffect = useOnLoadEffect(() => {
    let initialPage = 1;
    const currentUrl = new URLSearchParams(window.location.search);
    const urlPageStr = currentUrl.get('page');
    if (urlPageStr && urlPageStr.match(/^\d+$/)) {
      const urlPage = parseInt(urlPageStr);
      const totalPages = Math.ceil(items.length / itemsPerPage);
      if (1 <= urlPage && urlPage <= totalPages) {
        initialPage = urlPage;
      }
    }
    setCurrentPage(initialPage || 1);
  });

  function getPageUrlPart(): readonly [string, string | null] {
    const totalPages = Math.ceil(items.length / itemsPerPage);
    const page = totalPages > 1 ? currentPage + '' : null;
    return ['page', page];
  }

  const resetPagination = () => {
    setCurrentPage(1);
    setNCardsVisible(itemsPerPage);
  };

  const changePage = (pageNumber: number) => {
    if (containerRef?.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
      setLoading(true);
      setTimeout(() => {
        setCurrentPage(pageNumber);
        setLoading(false);
      }, 300);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const renderPagination = () => (
    <>
      {hasRunOnLoadEffect && nCardsVisible < items.length && (
        <Button
          outlined
          onClick={() => {
            setNCardsVisible(nCardsVisible + itemsPerPage);
          }}
          className={styles.listPaginationMobileButton}
        >
          Load more
        </Button>
      )}
      {hasRunOnLoadEffect && items.length > itemsPerPage && (
        <ListPagination
          currentPage={currentPage}
          totalItems={items.length}
          itemsPerPage={nCardsVisible}
          onPrevPageClick={() => {
            changePage(currentPage - 1);
          }}
          onNextPageClick={() => {
            changePage(currentPage + 1);
          }}
          onPageClick={(pageNumber: number) => {
            changePage(pageNumber);
          }}
        ></ListPagination>
      )}
    </>
  );

  return {
    currentPage,
    currentPageItems,
    loading,
    getPageUrlPart,
    resetPagination,
    renderPagination,
  };
}

export function useUpdateUrlFromFilters(
  getUrlParts: () => Array<readonly [string, string | null]>,
  dependencies: Array<unknown>,
): void {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    for (const [key, value] of getUrlParts()) {
      if (value) {
        urlSearchParams.set(key, value);
      }
    }

    const newQuery = urlSearchParams.toString();
    const newUrlPathAndQuery = window.location.pathname + (newQuery ? '?' + newQuery : '');
    history.replaceState(null, '', newUrlPathAndQuery);
  }, dependencies);
}

export function useAnimatedTypewritingTextsInfo(
  sentencesArrays: Array<Array<string>>,
  {
    transitionDuration = 750,
    transitionPeriod = 2000,
  }: { transitionDuration?: number; transitionPeriod?: number } = {},
): { currentSentenceIndex: number; nVisibleCharsArray: Array<number> } {
  if (
    !sentencesArrays
      .map(sentences => sentences.length)
      .every(sentencesLength => sentencesLength === sentencesArrays[0].length)
  ) {
    throw new Error('Not every sentencesArray has the same length');
  }
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [nVisibleCharsArray, setNVisibleCharsArray] = useState(
    sentencesArrays.map(sentences => sentences[0].length),
  );
  const [currentState, setCurrentState] = useState<'shrinking' | 'growing' | 'waiting'>('waiting');

  const sentenceTransitionsList = useRef<
    Array<{
      sentencesIndex: number;
      transitionTime: number;
      transitionDuration: number;
    }>
  >([]);

  useEffect(() => {
    if (currentState === 'waiting') {
      const timeout = setTimeout(() => {
        setCurrentState('shrinking');
      }, transitionPeriod);
      return () => {
        clearTimeout(timeout);
      };
    }

    if (sentenceTransitionsList.current.length === 0) {
      const currentSentences = sentencesArrays.map(sentences => sentences[currentSentenceIndex]);
      for (let i = 0; i < currentSentences.length; i++) {
        const sentence = currentSentences[i];

        const charTransitionDuration = transitionDuration / 2 / sentence.length;

        for (let j = 0; j < sentence.length; j++) {
          sentenceTransitionsList.current.push({
            sentencesIndex: i,
            transitionTime: j * charTransitionDuration,
            transitionDuration: charTransitionDuration,
          });
        }
      }
      sentenceTransitionsList.current = sortBy(
        sentenceTransitionsList.current,
        ({ transitionTime }) => transitionTime,
        'desc',
      );

      for (let i = sentenceTransitionsList.current.length - 1; i >= 0; i--) {
        const transition = sentenceTransitionsList.current[i];
        transition.transitionDuration =
          i === sentenceTransitionsList.current.length - 1
            ? 0
            : transition.transitionTime - sentenceTransitionsList.current[i + 1].transitionTime;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const sentenceTransition = sentenceTransitionsList.current.pop()!;

    const timeout = setTimeout(() => {
      function setCurrentNVisibleChars(nVisibleChars: number) {
        const newNVisibleCharsArray = [...nVisibleCharsArray];
        newNVisibleCharsArray[sentenceTransition.sentencesIndex] = nVisibleChars;
        setNVisibleCharsArray(newNVisibleCharsArray);
      }

      if (currentState === 'shrinking') {
        setCurrentNVisibleChars(nVisibleCharsArray[sentenceTransition.sentencesIndex] - 1);
        if (sentenceTransitionsList.current.length === 0) {
          const newSentenceIndex = (currentSentenceIndex + 1) % sentencesArrays[0].length;
          setCurrentSentenceIndex(newSentenceIndex);
          setCurrentState('growing');
        }
      }
      if (currentState === 'growing') {
        setCurrentNVisibleChars(nVisibleCharsArray[sentenceTransition.sentencesIndex] + 1);
        if (sentenceTransitionsList.current.length === 0) {
          setCurrentState('waiting');
        }
      }
    }, sentenceTransition.transitionDuration);
    return () => {
      clearTimeout(timeout);
    };
  }, [...nVisibleCharsArray, currentState]);
  return {
    currentSentenceIndex,
    nVisibleCharsArray,
  };
}
