import React from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { ButtonLinkType } from '../../../graphql-fragments/ButtonLink';
import { getUrlFromVersatileLink } from '../../../utils/sanity';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import ButtonLink from '../ButtonLink';
import ModuleLayout from '../ModuleLayout';
import SmartLink from '../SmartLink';
import * as styles from './NumbersGridModule.module.scss';

interface NumberType {
  superTitle?: string;
  number: string;
  numberUnit: string;
  subtitle?: string;
  text?: string;
  link?: ButtonLinkType;
  withColoredBackground?: boolean;
}

type NumberGridLayout = 'numbersGridUnderText' | 'numbersGridNextToText';

export type NumbersGridModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  button?: ButtonLinkType;
  className?: string;
  title: string;
  text: string;
  numbers: Array<NumberType>;
  layout: NumberGridLayout;
};

export function getModuleBgColor(props: NumbersGridModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function NumbersGridModule(props: NumbersGridModuleProps & CommonModuleProps): React.ReactElement {
  const { title, text, layout, numbers, button, className, moduleId, previousModuleBgColor } =
    props;

  function renderNumberContainer(number: NumberType, layout: NumberGridLayout) {
    return (
      <div
        className={clsx(
          styles.numberContainer,
          number.withColoredBackground && number.link && styles.withColoredBackground,
        )}
      >
        {number.superTitle && <span className={styles.superTitle}>{number.superTitle}</span>}
        {number.superTitle && layout === 'numbersGridUnderText' && (
          <div className={styles.divider}></div>
        )}
        <div className={styles.numberWrapper}>
          <span className={styles.number}>{number.number}</span>
          <span className={styles.subtitle}>{number.numberUnit}</span>
        </div>
        {number.subtitle && <p className={styles.description}>{number.subtitle}</p>}
        {number.text && <p className={styles.text}>{number.text}</p>}
        {number.link && (
          <span className={styles.link}>
            {number.link.title} <BsArrowRightShort className={styles.arrow} />
          </span>
        )}
      </div>
    );
  }

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      title={title}
      text={text}
      contentClassName={clsx(
        styles.contentContainer,
        layout === 'numbersGridNextToText' && styles.rowLayout,
      )}
      childrenClassName={styles.numbersGridWrapper}
    >
      <div className={styles.numbersGridContainer}>
        {numbers.map((number, i) =>
          number.link ? (
            <SmartLink
              to={getUrlFromVersatileLink(number.link)}
              key={i}
              className={styles.numberLink}
            >
              {renderNumberContainer(number, layout)}
            </SmartLink>
          ) : (
            <React.Fragment key={i}>{renderNumberContainer(number, layout)}</React.Fragment>
          ),
        )}
      </div>
      {button && layout === 'numbersGridUnderText' && (
        <ButtonLink to={button} linkClassName={styles.button}>
          {button.title}
        </ButtonLink>
      )}
    </ModuleLayout>
  );
}

export default NumbersGridModule;
