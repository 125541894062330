import BlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import groq from 'groq';
import React from 'react';
import PageSEO from '../components/PageSEO';
import CategoryTag from '../components/ui/CategoryTag';
import EmbedVideo from '../components/ui/EmbedVideo';
import Image from '../components/ui/Image';
import SanityLayout from '../components/ui/SanityLayout';
import SocialMediaShare from '../components/ui/SocialMediaShare';
import Video from '../components/ui/Video';
import ArticlesListingModule from '../components/ui/modules/ArticlesListingModule';
import CTAModule from '../components/ui/modules/CTAModule';
import { ButtonLinkType } from '../graphql-fragments/ButtonLink';
import { SanityImageType } from '../graphql-fragments/SanityImage';
import { LocalizedSEO } from '../graphql-fragments/seo';
import { withPagePreview } from '../preview/previewUtils';
import serializers from '../serializers';
import { RawPortableText } from '../types/types';
import { getVideoTypeByUrl } from '../utils/projectUtils';
import { getPortableTextAsString } from '../utils/sanity';
import { clsx } from '../utils/utils';
import * as styles from './CaseStudyPage.module.scss';

export const query = graphql`
  fragment CaseStudy on SanityHpWebsiteCaseStudy {
    _id
    title
    slug {
      current
    }
    category {
      title
    }
    goal
    industry
    companySize
    location
    smallImage {
      ...SanityImage
    }
    image {
      ...SanityImage
    }
    resultsNumbers {
      title
      _rawText(resolveReferences: { maxDepth: 4 })
    }
    introductionVideo {
      url
      isAVerticalVideo
    }
    _rawDescription(resolveReferences: { maxDepth: 4 })
    _rawIntroduction(resolveReferences: { maxDepth: 4 })
    _rawBackground(resolveReferences: { maxDepth: 4 })
    backgroundImage {
      ...SanityImage
    }
    backgroundVideo {
      url
      isAVerticalVideo
      quoteTitle
      quote
    }
    _rawChallenge(resolveReferences: { maxDepth: 4 })
    challengeImage {
      ...SanityImage
    }
    challengeVideo {
      url
      isAVerticalVideo
      quoteTitle
      quote
    }
    _rawSolution(resolveReferences: { maxDepth: 4 })
    solutionImage {
      ...SanityImage
    }
    solutionVideo {
      url
      isAVerticalVideo
      quoteTitle
      quote
    }
    _rawResults(resolveReferences: { maxDepth: 4 })
    beforeVideoUrl
    beforeVideoViewsNumber
    beforeImage {
      ...SanityImage
    }
    afterVideoUrl
    afterVideoViewsNumber
    afterImage {
      ...SanityImage
    }
    _rawSummaryText(resolveReferences: { maxDepth: 4 })
    ctaSection {
      title
      text
      hideTextFieldInsteadOfUsingDefault
      hideCtaSection
      button {
        ...ButtonLink
      }
    }
    seo {
      ...SEO
    }
  }

  query GetCaseStudyPageById($id: String!) {
    sanityHpWebsiteCaseStudy(_id: { eq: $id }) {
      ...CaseStudy
    }
    allSanityCaseStudy: allSanityHpWebsiteCaseStudy(sort: { orderRank: ASC }) {
      nodes {
        ...CaseStudy
      }
    }
  }
`;

const groqQuery = groq`{
  "sanityHpWebsiteCaseStudy": *[_id == $id][0] {
     ...
  },
  "allSanityCaseStudy": {
    "nodes": *[_type == "hp-website-caseStudy"] | order(orderRank asc) {
      ...
    }
  }
}`;

interface ResultNumber {
  title: string;
  _rawText: RawPortableText;
}

interface Video {
  url: string;
  isAVerticalVideo?: boolean;
}

type VideoAndQuote = Video & {
  quoteTitle?: string;
  quote?: string;
};

export interface CaseStudy {
  _id: string;
  title: string;
  slug: {
    current: string;
  };
  category?: {
    title: string;
  };
  goal?: string;
  industry?: string;
  companySize?: string;
  location?: string;
  smallImage: SanityImageType;
  image: SanityImageType;
  resultsNumbers?: Array<ResultNumber>;
  introductionVideo?: Video;
  _rawDescription: RawPortableText;
  _rawIntroduction: RawPortableText;
  _rawBackground?: RawPortableText;
  backgroundImage?: SanityImageType;
  backgroundVideo?: VideoAndQuote;
  _rawChallenge?: RawPortableText;
  challengeImage?: SanityImageType;
  challengeVideo?: VideoAndQuote;
  _rawSolution?: RawPortableText;
  solutionImage?: SanityImageType;
  solutionVideo?: VideoAndQuote;
  _rawResults: RawPortableText;
  beforeVideoUrl?: string;
  beforeVideoViewsNumber?: string;
  beforeImage?: SanityImageType;
  afterVideoUrl?: string;
  afterVideoViewsNumber?: string;
  afterImage?: SanityImageType;
  _rawSummaryText: RawPortableText;
  ctaSection?: {
    title?: string;
    text?: string;
    hideTextFieldInsteadOfUsingDefault?: boolean;
    hideCtaSection?: boolean;
    button?: ButtonLinkType;
  };
  seo?: LocalizedSEO;
}

interface QueryData {
  sanityHpWebsiteCaseStudy: CaseStudy;
  allSanityCaseStudy: {
    nodes: Array<CaseStudy>;
  };
}

function CaseStudySection({
  title,
  text,
  image,
  video,
  withoutDivider,
  largerTextFontSize,
  noMarginAfterText,
  children,
}: {
  title: string;
  text: RawPortableText;
  image?: SanityImageType;
  video?: VideoAndQuote;
  withoutDivider?: boolean;
  largerTextFontSize?: boolean;
  noMarginAfterText?: boolean;
  children?: React.ReactNode;
}) {
  const videoType = video?.url && getVideoTypeByUrl(video.url);
  const isAVerticalVideo =
    video?.url &&
    !!(
      video.isAVerticalVideo ||
      video.url.match(/\/shorts\//) ||
      videoType === 'tiktok' ||
      videoType === 'instagram'
    );

  return (
    <div className={styles.section}>
      <div
        className={clsx(
          styles.titleAndTextContainer,
          noMarginAfterText && styles.noMarginAfterText,
        )}
      >
        <div className={styles.sectionTitleContainer}>
          <h2 className={styles.sectionTitle}>{title}</h2>
          <div className={styles.titleDivider}></div>
        </div>
        <BlockContent
          renderContainerOnSingleChild
          blocks={text}
          serializers={serializers}
          className={clsx(styles.text, largerTextFontSize && styles.largerTextFontSize)}
        />
      </div>
      {image && (
        <div className={styles.imageContainer}>
          <Image alt={title} image={image} width={1920} height={1080} cover />
        </div>
      )}
      {video?.url && (
        <div className={clsx(styles.videoContainer, isAVerticalVideo && styles.verticalVideo)}>
          <div className={clsx(styles.videoWrapper)}>
            <div className={styles.videoBackground}></div>
            <Video
              url={video.url}
              className={clsx(styles.video, videoType === 'youtube' && styles.youtubeVideo)}
              fitContent
              isAVerticalVideo={video.isAVerticalVideo}
            />
          </div>
          {(video.quoteTitle || video.quote) && (
            <div className={styles.quoteContainer}>
              <div className={styles.quoteWrapper}>
                {video.quoteTitle && (
                  <span className={styles.quoteTitle}>— {video.quoteTitle}</span>
                )}
                {video.quote && <blockquote className={styles.quote}>"{video.quote}"</blockquote>}
              </div>
            </div>
          )}
        </div>
      )}
      {!image && !video && !withoutDivider && <div className={styles.sectionDivider}></div>}
      {children}
    </div>
  );
}

function BeforeAndAfterContainer({
  title,
  videoUrl,
  videoThumbail,
  videoViews,
  image,
}: {
  title: 'Before' | 'After';
  videoUrl?: string;
  videoThumbail?: SanityImageType;
  videoViews?: string;
  image?: SanityImageType;
}) {
  return (
    <div className={clsx(styles.beforeAndAfterWrapper, title === 'Before' && styles.before)}>
      <div className={styles.beforeAndAfterTitleContainer}>
        <span className={styles.beforeAndAfterTitle}>{title}</span>
      </div>
      <div className={styles.beforeAndAfterMediaContainer}>
        {videoUrl && videoThumbail && <EmbedVideo url={videoUrl} thumbnail={videoThumbail} />}
        {!videoUrl && image && <Image image={image} />}
      </div>
      {videoViews && (
        <div className={styles.videoViewsContainer}>
          <span className={styles.videoViews}>{videoViews}</span>
        </div>
      )}
    </div>
  );
}

const CaseStudyPage = withPagePreview<QueryData>(
  {
    groqQuery,
  },
  ({ data }) => {
    const {
      title,
      smallImage,
      goal,
      industry,
      companySize,
      location,
      category,
      _rawDescription,
      _rawBackground,
      backgroundImage,
      backgroundVideo,
      _rawChallenge,
      challengeImage,
      challengeVideo,
      _rawSolution,
      solutionImage,
      solutionVideo,
      _rawResults,
      beforeImage,
      beforeVideoUrl,
      beforeVideoViewsNumber,
      afterImage,
      afterVideoUrl,
      afterVideoViewsNumber,
      _rawSummaryText,
      image,
      resultsNumbers,
      _rawIntroduction,
      introductionVideo,
      ctaSection,
      seo,
    } = data.sanityHpWebsiteCaseStudy;

    function renderInfo(title: string, info: string) {
      return (
        <div className={styles.info}>
          <p className={styles.infoTitle}>{title} </p>
          <span>{info}</span>
        </div>
      );
    }

    function renderResultNumber(title: string, text: RawPortableText, i: number) {
      return (
        <div className={clsx(styles.resultContainer, styles.darkTheme)} key={i}>
          <span className={styles.resultTitle}> {title}</span>
          <BlockContent
            renderContainerOnSingleChild
            blocks={text}
            serializers={serializers}
            className={styles.resultText}
          />
        </div>
      );
    }

    const currentCaseStudyId = data.sanityHpWebsiteCaseStudy._id;

    const allCaseStudyExceptCurrent = data.allSanityCaseStudy.nodes.filter(
      caseStudy => caseStudy.slug?.current && !currentCaseStudyId.includes(caseStudy._id),
    );

    const caseStudiesListing = allCaseStudyExceptCurrent.map(caseStudy => {
      return {
        _id: caseStudy._id,
        image: caseStudy.image,
        title: caseStudy.title,
        slug: {
          current: caseStudy.slug.current,
        },
        excerpt: getPortableTextAsString(caseStudy._rawDescription),
        categoryTitle: caseStudy.category?.title,
      };
    });

    return (
      <SanityLayout pageTheme="light">
        <PageSEO
          defaultTitle={title}
          defaultDescription={getPortableTextAsString(_rawDescription)}
          defaultImageUrl={image.asset.url}
          pageSEO={seo}
        />
        <div className={styles.caseStudyPageContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.heroContainer}>
              <div className={styles.smallImageContainer}>
                <Image alt={'Profile Image of ' + title} image={smallImage} />
              </div>
              <div className={styles.titleAndCategoryContainer}>
                {category && (
                  <CategoryTag categoryTitle={category.title} className={styles.category} />
                )}
                <h1 className={styles.title}>{title}</h1>
              </div>
            </div>
            <BlockContent
              renderContainerOnSingleChild
              blocks={_rawDescription}
              serializers={serializers}
              className={styles.description}
            />
            {(goal || industry || companySize || location) && (
              <div className={styles.infoContainer}>
                <div className={styles.infoWrapper}>
                  {goal && renderInfo('Goal', goal)}
                  {industry && renderInfo('Industry', industry)}
                  {companySize && renderInfo('Company Size', companySize)}
                  {location && renderInfo('Location', location)}
                </div>
                <div className={styles.infoDivider}></div>
              </div>
            )}
            <div className={styles.imageContainer}>
              <Image image={image} alt={title} width={1400} height={560} cover />
            </div>
            {resultsNumbers && resultsNumbers.length > 0 && (
              <div className={styles.resultsItemsContainer}>
                {resultsNumbers.map((item, i) => renderResultNumber(item.title, item._rawText, i))}
              </div>
            )}
            <div className={styles.sectionsContainer}>
              {_rawIntroduction && (
                <CaseStudySection
                  title="Introduction"
                  text={_rawIntroduction}
                  video={introductionVideo}
                  largerTextFontSize
                ></CaseStudySection>
              )}
              {_rawBackground && (
                <CaseStudySection
                  title="Background"
                  text={_rawBackground}
                  image={backgroundImage}
                  video={backgroundVideo}
                ></CaseStudySection>
              )}
              {_rawChallenge && (
                <CaseStudySection
                  title="Challenge"
                  text={_rawChallenge}
                  image={challengeImage}
                  video={challengeVideo}
                ></CaseStudySection>
              )}
              {_rawSolution && (
                <CaseStudySection
                  title="Solution"
                  text={_rawSolution}
                  image={solutionImage}
                  video={solutionVideo}
                ></CaseStudySection>
              )}
              {_rawResults && (
                <CaseStudySection
                  title="Results"
                  text={_rawResults}
                  withoutDivider
                  noMarginAfterText={
                    !beforeImage && !beforeVideoUrl && !afterImage && !afterVideoUrl
                  }
                >
                  {' '}
                  {(beforeImage || beforeVideoUrl) && (afterImage || afterVideoUrl) && (
                    <div className={styles.beforeAndAfterContainer}>
                      <BeforeAndAfterContainer
                        title="Before"
                        videoUrl={beforeVideoUrl}
                        videoThumbail={beforeImage}
                        videoViews={beforeVideoViewsNumber}
                        image={beforeImage}
                      ></BeforeAndAfterContainer>
                      <BeforeAndAfterContainer
                        title="After"
                        videoUrl={afterVideoUrl}
                        videoThumbail={afterImage}
                        videoViews={afterVideoViewsNumber}
                        image={afterImage}
                      ></BeforeAndAfterContainer>
                    </div>
                  )}
                </CaseStudySection>
              )}
            </div>
          </div>
        </div>
        {_rawSummaryText && (
          <div className={clsx(styles.summaryContainer, styles.darkTheme)}>
            <div className={styles.summaryWrapper}>
              <div className={styles.summaryContentContainer}>
                <div className={styles.summaryTextContainer}>
                  <div className={styles.sectionTitleContainer}>
                    <h2 className={styles.sectionTitle}>Summary</h2>
                    <div className={styles.titleDivider}></div>
                  </div>
                  <BlockContent
                    renderContainerOnSingleChild
                    blocks={_rawSummaryText}
                    serializers={serializers}
                    className={styles.text}
                  />
                </div>
                {resultsNumbers && resultsNumbers.length > 0 && (
                  <>
                    <div className={clsx(styles.sectionDivider, styles.blueDivider)}></div>
                    <div className={styles.summaryResultsItemsContainer}>
                      <div className={clsx(styles.summaryItemsContainer, styles.darkTheme)}>
                        {resultsNumbers.map((summaryItem, i) =>
                          renderResultNumber(summaryItem.title, summaryItem._rawText, i),
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className={clsx(styles.sectionDivider)}></div>
              <div className={styles.shareContainer}>
                <div className={styles.shareWrapper}>
                  <div className={styles.sectionTitleContainer}>
                    <h2 className={styles.sectionTitle}>Share case study</h2>
                    <div className={styles.titleDivider}></div>
                  </div>
                  <SocialMediaShare className={styles.socialMediaShare} theme="dark" />
                </div>
              </div>
            </div>
          </div>
        )}
        {caseStudiesListing.length > 0 && (
          <ArticlesListingModule
            backgroundColor={'light'}
            previousModuleBgColor={'dark'}
            referencesToUse="chooseManually"
            articleReferences={caseStudiesListing}
            articleType="caseStudy"
            title={`Other case studies`}
            withPagination
            dontUpdatePageOnUrl
            itemsPerPage={3}
          />
        )}
        {!ctaSection?.hideCtaSection && (
          <CTAModule
            // WARNING: Using summary background color as previousModuleBgColor if no listing
            previousModuleBgColor={caseStudiesListing.length > 0 ? 'light' : 'dark'}
            title={ctaSection?.title}
            text={
              ctaSection?.text || (ctaSection?.hideTextFieldInsteadOfUsingDefault ? ' ' : undefined)
            }
            button={ctaSection?.button}
          />
        )}
      </SanityLayout>
    );
  },
);

export default CaseStudyPage;
