// extracted by mini-css-extract-plugin
export var columnContainer = "Footer-module--columnContainer--a8b5a";
export var companyLogo = "Footer-module--companyLogo--1c278";
export var companyWrapper = "Footer-module--companyWrapper--ea51d";
export var contentCentered = "Footer-module--contentCentered--9f7c5";
export var copyright = "Footer-module--copyright--3b840";
export var desktop = "Footer-module--desktop--47fba";
export var divider = "Footer-module--divider--95e90";
export var footer = "Footer-module--footer--8dcbc";
export var footerContainer = "Footer-module--footerContainer--88188";
export var footerWrapper = "Footer-module--footerWrapper--63158";
export var legal = "Footer-module--legal--3bb25";
export var legalTitle = "Footer-module--legalTitle--7837a";
export var link = "Footer-module--link--57e30";
export var logo = "Footer-module--logo--b5f37";
export var logoContainer = "Footer-module--logoContainer--7f664";
export var mobile = "Footer-module--mobile--90790";
export var navigation = "Footer-module--navigation--13746";
export var newsletter = "Footer-module--newsletter--35439";
export var newsletterWrapper = "Footer-module--newsletterWrapper--f2941";
export var row = "Footer-module--row--56c3c";
export var subSectionTitle = "Footer-module--subSectionTitle--27c12";