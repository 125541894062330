import React from 'react';
import { SanityImage } from 'sanity-image';

import { SanityImageProps } from 'sanity-image/dist/types';
import { SanityImageType } from '../../graphql-fragments/SanityImage';
import { useSanityConfigData } from '../../utils/sanity';
import { clsx } from '../../utils/utils';
import * as styles from './Image.module.scss';

export type BasicImage = {
  url: string;
  alt?: string;
};

interface ImageProps
  extends Omit<SanityImageProps, 'id'>,
    Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'width' | 'height'> {
  image: SanityImageType | BasicImage;
  cover?: boolean;
  eagerLoading?: boolean;
  className?: string;
}

const Image = ({
  image,
  cover,
  eagerLoading,
  className,
  ...restProps
}: ImageProps): React.ReactElement => {
  const sanityConfigData = useSanityConfigData();

  function isSanityImage(image: BasicImage | SanityImageType): image is SanityImageType {
    return 'asset' in image && !!image.asset._id;
  }

  return (
    <>
      {isSanityImage(image) ? (
        <SanityImage
          className={clsx(styles.image, className)}
          {...sanityConfigData}
          id={image.asset._id}
          hotspot={image.hotspot}
          crop={image.crop}
          preview={image.asset.metadata?.lqip}
          alt={image.asset.altText || ''}
          loading={eagerLoading ? 'eager' : 'lazy'}
          mode={cover ? 'cover' : 'contain'}
          {...restProps}
        />
      ) : (
        <img src={image.url} alt={image.alt || ''} className={className}></img>
      )}
    </>
  );
};

export default Image;
