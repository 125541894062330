import React from 'react';

import { useModalForm } from '../../contexts/ModalFormContext';
import { FormField } from '../../templates/InsightPage';
import { VersatileLink } from '../../types/types';
import {
  getPortableText,
  getPortableTextAsString,
  getUrlFromVersatileLink,
} from '../../utils/sanity';
import { clsx, slugify } from '../../utils/utils';
import Button, { ButtonProps } from './Button';
import * as styles from './ButtonLink.module.scss';
import SmartLink from './SmartLink';

export interface ButtonLinkProps extends ButtonProps {
  to: VersatileLink;
  linkClassName?: string;
  modalFormButtonClassName?: string;
}

type FormFieldWithId = {
  id: string;
} & FormField;

const ButtonLink = ({
  to,
  linkClassName,
  modalFormButtonClassName,
  ...restProps
}: ButtonLinkProps): React.ReactElement => {
  const additionalFormField = {
    crmFieldId: 'acceptTheTerms',
    fieldType: 'singleCheckbox',
    isFieldRequired: false,
    _rawText: getPortableText(['I consent to receive marketing communications']),
  } as const;

  const formFieldsWithIds: Array<FormFieldWithId> = to.downloadFileFormModal
    ? [...to.downloadFileFormModal.formFields, additionalFormField].map(formField => ({
        id: slugify(
          formField.fieldType === 'singleCheckbox'
            ? getPortableTextAsString(formField._rawText)
            : formField.title,
        ),
        ...formField,
      }))
    : [];

  const { showModalForm } = useModalForm();

  if (to.downloadFileFormModal && formFieldsWithIds.length > 1) {
    return (
      <Button
        {...restProps}
        className={clsx(restProps.className, modalFormButtonClassName)}
        onClick={() =>
          showModalForm({
            modalForm: to.downloadFileFormModal,
            formFieldsWithIds,
          })
        }
      ></Button>
    );
  } else {
    return (
      <SmartLink
        className={clsx(styles.link, linkClassName)}
        to={getUrlFromVersatileLink(to)}
        tabIndex={-1}
      >
        <Button {...restProps}></Button>
      </SmartLink>
    );
  }
};

export default ButtonLink;
