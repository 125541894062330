import React, { ReactNode } from 'react';
import { clsx } from '../../utils/utils';

import * as styles from './Button.module.scss';

export interface ButtonProps extends Omit<React.HTMLAttributes<HTMLElement>, 'children'> {
  children: string | ReactNode;
  outlined?: boolean;
  backgroundColor?: 'white';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: 'submit';
  withoutIconAnimation?: boolean;
  className?: string;
}

const Button = ({
  children,
  outlined,
  backgroundColor,
  onClick,
  type,
  withoutIconAnimation,
  className,
  ...restProps
}: ButtonProps): React.ReactElement => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        styles.button,
        outlined && styles.outlined,
        backgroundColor === 'white' && styles.white,
        withoutIconAnimation && styles.withoutIconAnimation,
        className,
      )}
      type={type === 'submit' ? 'submit' : 'button'}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default Button;
