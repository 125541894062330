import { graphql } from 'gatsby';
import React from 'react';
import * as styles from './ModulesContent.module.scss';

import AccordionModule, {
  AccordionModuleProps,
  getModuleBgColor as getAccordionModuleBgColor,
} from './ui/modules/AccordionModule';
import AnimatedTextAndMediaModule, {
  AnimatedTextAndMediaModuleProps,
  getModuleBgColor as getAnimatedTextAndMediaModuleBgColor,
} from './ui/modules/AnimatedTextAndMediaModule';
import ArticlesListingModule, {
  ArticlesListingModuleProps,
  getModuleBgColor as getArticlesListingModuleBgColor,
} from './ui/modules/ArticlesListingModule';
import BooksModule, {
  BooksModuleProps,
  getModuleBgColor as getBooksModuleBgColor,
} from './ui/modules/BooksModule';
import CaseStudiesListingModule, {
  CaseStudiesListingModuleProps,
  getModuleBgColor as getCaseStudiesListingModuleBgColor,
} from './ui/modules/CaseStudiesListingModule';
import FeaturedArticlesModule, {
  FeaturedArticlesModuleProps,
  getModuleBgColor as getFeaturedArticlesModuleBgColor,
} from './ui/modules/FeaturedArticlesModule';
import FeaturedCaseStudiesModule, {
  FeaturedCaseStudiesModuleProps,
  getModuleBgColor as getFeaturedCaseStudiesModuleBgColor,
} from './ui/modules/FeaturedCaseStudiesModule';
import FeaturedInModule, {
  FeaturedInModuleProps,
  getModuleBgColor as getFeaturedInModuleBgColor,
} from './ui/modules/FeaturedInModule';
import FormModule, {
  FormModuleProps,
  getModuleBgColor as getFormModuleBgColor,
} from './ui/modules/FormModule';
import ImageGridModule, {
  ImageGridModuleProps,
  getModuleBgColor as getImageGridModuleBgColor,
} from './ui/modules/ImageGridModule';
import InsightsCarouselModule, {
  InsightsCarouselModuleProps,
  getModuleBgColor as getInsightsCarouselModuleBgColor,
} from './ui/modules/InsightsCarouselModule';
import LogosModule, {
  LogosModuleProps,
  getModuleBgColor as getLogosModuleBgColor,
} from './ui/modules/LogosModule';
import NumbersGridModule, {
  NumbersGridModuleProps,
  getModuleBgColor as getNumbersGridModuleBgColor,
} from './ui/modules/NumbersGridModule';
import QuoteModule, {
  QuoteModuleProps,
  getModuleBgColor as getQuoteModuleBgColor,
} from './ui/modules/QuoteModule';
import ReviewsModule, {
  ReviewsModuleProps,
  getModuleBgColor as getReviewsModuleBgColor,
} from './ui/modules/ReviewsModule';
import TeamGridModule, {
  TeamGridModuleProps,
  getModuleBgColor as getTeamGridModuleBgColor,
} from './ui/modules/TeamGridModule';
import TestimonialsModule, {
  TestimonialsModuleProps,
  getModuleBgColor as getTestimonialsModuleBgColor,
} from './ui/modules/TestimonialsModule';
import TextAndMediaModule, {
  TextAndMediaModuleProps,
  getModuleBgColor as getTextAndMediaModuleBgColor,
} from './ui/modules/TextAndMediaModule';
import VideoReportsModule, {
  VideoReportsModuleProps,
  getModuleBgColor as getVideoReportsModuleBgColor,
} from './ui/modules/VideoReportsModule';
import WordSliderModule, {
  WordSliderModuleProps,
  getModuleBgColor as getWordSliderModuleBgColor,
} from './ui/modules/WordSliderModule';

export const ModulesFragment = graphql`
  fragment Modules on SanityHpWebsiteAccordionModuleOrHpWebsiteAnimatedTextAndMediaModuleOrHpWebsiteArticlesListingModuleOrHpWebsiteBooksModuleOrHpWebsiteCaseStudiesListingModuleOrHpWebsiteFeaturedArticlesModuleOrHpWebsiteFeaturedCaseStudiesModuleOrHpWebsiteFeaturedInModuleOrHpWebsiteFormModuleOrHpWebsiteImageGridModuleOrHpWebsiteInsightsCarouselModuleOrHpWebsiteLogosModuleOrHpWebsiteNumbersGridModuleOrHpWebsiteQuoteModuleOrHpWebsiteReviewsModuleOrHpWebsiteTeamGridModuleOrHpWebsiteTestimonialsModuleOrHpWebsiteTextAndMediaModuleOrHpWebsiteVideoReportsModuleOrHpWebsiteWordSliderModule {
    __typename
    ... on SanityHpWebsiteAccordionModule {
      moduleId
      disabled
      backgroundColor
      title
      text
      accordions {
        title
        _rawText(resolveReferences: { maxDepth: 4 })
        image {
          ...SanityImage
        }
      }
      primaryButton {
        ...ButtonLink
      }
      secondaryButton {
        ...ButtonLink
      }
    }
    ... on SanityHpWebsiteAnimatedTextAndMediaModule {
      moduleId
      disabled
      backgroundColor
      mediaType
      image {
        ...SanityImage
      }
      video
      introText
      animatedSentence {
        firstFixedWord
        firstAnimatedWords {
          sentence
        }
        secondFixedWord
        secondAnimatedWords {
          sentence
        }
      }
      buttonLink {
        ...ButtonLink
      }
    }
    ... on SanityHpWebsiteArticlesListingModule {
      moduleId
      disabled
      backgroundColor
      title
      referencesToUse
      articleReferences {
        ...Article
      }
      showAllArticlesButton
    }
    ... on SanityHpWebsiteBooksModule {
      moduleId
      disabled
      backgroundColor
      title
      text
      books {
        title
        description
        mobileImage {
          ...SanityImage
        }
        image {
          ...SanityImage
        }
        ctaButton {
          ...ButtonLink
        }
        bookPage {
          ...PageReference
        }
      }
    }
    ... on SanityHpWebsiteCaseStudiesListingModule {
      moduleId
      disabled
      backgroundColor
      title
      referencesToUse
      caseStudyReferences {
        ...CaseStudy
      }
    }
    ... on SanityHpWebsiteFeaturedArticlesModule {
      moduleId
      disabled
      backgroundColor
      articles {
        ...Article
      }
    }
    ... on SanityHpWebsiteFeaturedCaseStudiesModule {
      moduleId
      disabled
      backgroundColor
      title
      caseStudies {
        ...CaseStudy
      }
      moduleLayout
      showExploreCaseStudiesButton
    }
    ... on SanityHpWebsiteFeaturedInModule {
      moduleId
      disabled
      backgroundColor
      title
      referencesToUse
      mediaHighlights {
        title
        url
        image {
          ...SanityImage
        }
        mediaType
      }
    }
    ... on SanityHpWebsiteFormModule {
      moduleId
      disabled
      backgroundColor
      formType
      title
      subtitle
      text
      form {
        fields {
          fieldType
          displayType
          title
          crmFieldId
          withEmailValidation
          selectOptions {
            title
            crmValueStored
          }
          isFieldRequired
          _rawText(resolveReferences: { maxDepth: 4 })
        }

        submitButtonText
        thankYouScreen {
          title
          subtitle
          _rawText(resolveReferences: { maxDepth: 4 })
          scheduleACallButton {
            ...ButtonLink
          }
        }
      }
    }
    ... on SanityHpWebsiteImageGridModule {
      moduleId
      disabled
      backgroundColor
      title
      images {
        ...SanityImage
      }
      withPagination
      itemsPerPage
    }
    ... on SanityHpWebsiteInsightsCarouselModule {
      moduleId
      disabled
      backgroundColor
      title
      referencesToUse
      insightReferences {
        ...Insight
      }
    }
    ... on SanityHpWebsiteLogosModule {
      moduleId
      disabled
      backgroundColor
      title
      useClientBrandsLogos
      logosToUse
      referenceLogos {
        darkLogo {
          ...SanityImage
        }
        whiteLogo {
          ...SanityImage
        }
      }
      logos {
        darkLogo {
          ...SanityImage
        }
        whiteLogo {
          ...SanityImage
        }
      }
    }
    ... on SanityHpWebsiteNumbersGridModule {
      moduleId
      disabled
      backgroundColor
      title
      text
      layout
      numbers {
        superTitle
        number
        numberUnit
        subtitle
        text
        withColoredBackground
        link {
          ...ButtonLink
        }
      }
      button {
        ...ButtonLink
      }
    }
    ... on SanityHpWebsiteQuoteModule {
      moduleId
      disabled
      backgroundColor
      quote
      author
      position
    }
    ... on SanityHpWebsiteReviewsModule {
      moduleId
      disabled
      backgroundColor
      title
      reviewsToUse
      reviews {
        author
        image {
          ...SanityImage
        }
        review
        rating
        source {
          title
          logo {
            ...SanityImage
          }
        }
        category
        date
      }
      withPagination
      itemsPerPage
    }
    ... on SanityHpWebsiteTeamGridModule {
      moduleId
      disabled
      backgroundColor
      title
      text
      referencesToUse
      teamMemberReferences {
        ...TeamMember
      }
    }
    ... on SanityHpWebsiteTestimonialsModule {
      moduleId
      disabled
      backgroundColor
      referencesToUse
      testimonials {
        quote
        author
        position
        image {
          ...SanityImage
        }
      }
    }
    ... on SanityHpWebsiteTextAndMediaModule {
      moduleId
      disabled
      backgroundColor
      title
      subtitle
      mediaType
      image {
        ...SanityImage
      }
      imageSubtitle
      imageHasTransparentBackground
      video
      button {
        ...ButtonLink
      }
      moduleAligment
      withHighlightedBackground
      _rawText(resolveReferences: { maxDepth: 4 })
      _rawModalContent(resolveReferences: { maxDepth: 4 })
      modalContentButtonLabel
      rating
      ratingDescription
    }
    ... on SanityHpWebsiteVideoReportsModule {
      moduleId
      disabled
      backgroundColor
      title
      videoReports {
        title
        subtitle
        image {
          ...SanityImage
        }
        videoUrl
      }
    }
    ... on SanityHpWebsiteWordSliderModule {
      moduleId
      disabled
      backgroundColor
      firstRow {
        words
      }
      secondRow {
        words
      }
    }
  }
`;

export type ModuleBackgroundColor = 'light' | 'dark' | 'red' | 'gray';

export interface ModulesContentProps {
  modules: Array<Module>;
  previousItemBgColor?: ModuleBackgroundColor;
  pageTheme?: ModuleBackgroundColor;
}

export interface CommonModuleProps {
  moduleId?: string;
  disabled?: boolean | null;
  previousModuleBgColor?: ModuleBackgroundColor;
}

export type Module =
  | (AccordionModuleProps & CommonModuleProps & { __typename: 'SanityHpWebsiteAccordionModule' })
  | (AnimatedTextAndMediaModuleProps &
      CommonModuleProps & { __typename: 'SanityHpWebsiteAnimatedTextAndMediaModule' })
  | (ArticlesListingModuleProps &
      CommonModuleProps & { __typename: 'SanityHpWebsiteArticlesListingModule' })
  | (BooksModuleProps & CommonModuleProps & { __typename: 'SanityHpWebsiteBooksModule' })
  | (CaseStudiesListingModuleProps &
      CommonModuleProps & { __typename: 'SanityHpWebsiteCaseStudiesListingModule' })
  | (FeaturedArticlesModuleProps &
      CommonModuleProps & { __typename: 'SanityHpWebsiteFeaturedArticlesModule' })
  | (FeaturedCaseStudiesModuleProps &
      CommonModuleProps & { __typename: 'SanityHpWebsiteFeaturedCaseStudiesModule' })
  | (FeaturedInModuleProps & CommonModuleProps & { __typename: 'SanityHpWebsiteFeaturedInModule' })
  | (FormModuleProps & CommonModuleProps & { __typename: 'SanityHpWebsiteFormModule' })
  | (ImageGridModuleProps & CommonModuleProps & { __typename: 'SanityHpWebsiteImageGridModule' })
  | (InsightsCarouselModuleProps &
      CommonModuleProps & { __typename: 'SanityHpWebsiteInsightsCarouselModule' })
  | (LogosModuleProps & CommonModuleProps & { __typename: 'SanityHpWebsiteLogosModule' })
  | (NumbersGridModuleProps &
      CommonModuleProps & { __typename: 'SanityHpWebsiteNumbersGridModule' })
  | (QuoteModuleProps & CommonModuleProps & { __typename: 'SanityHpWebsiteQuoteModule' })
  | (ReviewsModuleProps & CommonModuleProps & { __typename: 'SanityHpWebsiteReviewsModule' })
  | (TeamGridModuleProps & CommonModuleProps & { __typename: 'SanityHpWebsiteTeamGridModule' })
  | (TestimonialsModuleProps &
      CommonModuleProps & { __typename: 'SanityHpWebsiteTestimonialsModule' })
  | (TextAndMediaModuleProps &
      CommonModuleProps & { __typename: 'SanityHpWebsiteTextAndMediaModule' })
  | (VideoReportsModuleProps &
      CommonModuleProps & { __typename: 'SanityHpWebsiteVideoReportsModule' })
  | (WordSliderModuleProps & CommonModuleProps & { __typename: 'SanityHpWebsiteWordSliderModule' });

function ModulesContent({
  modules,
  previousItemBgColor,
  pageTheme,
}: ModulesContentProps): React.ReactElement {
  const enabledModules = modules.filter(module => !module.disabled);

  return (
    <>
      {enabledModules.map((module, moduleIndex) => {
        const moduleKey = 'module-' + moduleIndex;

        let previousModuleBgColor = previousItemBgColor;
        if (moduleIndex > 0) {
          const previousModule = enabledModules[moduleIndex - 1];
          if (previousModule.__typename === 'SanityHpWebsiteAccordionModule') {
            previousModuleBgColor = getAccordionModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteAnimatedTextAndMediaModule') {
            previousModuleBgColor = getAnimatedTextAndMediaModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteArticlesListingModule') {
            previousModuleBgColor = getArticlesListingModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteBooksModule') {
            previousModuleBgColor = getBooksModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteCaseStudiesListingModule') {
            previousModuleBgColor = getCaseStudiesListingModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteFeaturedArticlesModule') {
            previousModuleBgColor = getFeaturedArticlesModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteFeaturedCaseStudiesModule') {
            previousModuleBgColor = getFeaturedCaseStudiesModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteFeaturedInModule') {
            previousModuleBgColor = getFeaturedInModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteFormModule') {
            previousModuleBgColor = getFormModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteImageGridModule') {
            previousModuleBgColor = getImageGridModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteInsightsCarouselModule') {
            previousModuleBgColor = getInsightsCarouselModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteLogosModule') {
            previousModuleBgColor = getLogosModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteNumbersGridModule') {
            previousModuleBgColor = getNumbersGridModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteQuoteModule') {
            previousModuleBgColor = getQuoteModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteReviewsModule') {
            previousModuleBgColor = getReviewsModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteTeamGridModule') {
            previousModuleBgColor = getTeamGridModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteTestimonialsModule') {
            previousModuleBgColor = getTestimonialsModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteTextAndMediaModule') {
            previousModuleBgColor = getTextAndMediaModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteVideoReportsModule') {
            previousModuleBgColor = getVideoReportsModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityHpWebsiteWordSliderModule') {
            previousModuleBgColor = getWordSliderModuleBgColor(previousModule);
          } else {
            throw new Error(
              // @ts-ignore
              'Got unexpected previousModule with typename ' + previousModule.__typename,
            );
          }
        }

        const commonModuleProps: CommonModuleProps = {
          previousModuleBgColor,
        };

        if (module.__typename === 'SanityHpWebsiteAccordionModule') {
          return (
            <AccordionModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></AccordionModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteAnimatedTextAndMediaModule') {
          return (
            <AnimatedTextAndMediaModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></AnimatedTextAndMediaModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteArticlesListingModule') {
          return (
            <ArticlesListingModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></ArticlesListingModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteBooksModule') {
          return (
            <BooksModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></BooksModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteCaseStudiesListingModule') {
          return (
            <CaseStudiesListingModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></CaseStudiesListingModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteFeaturedArticlesModule') {
          return (
            <FeaturedArticlesModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></FeaturedArticlesModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteFeaturedCaseStudiesModule') {
          return (
            <FeaturedCaseStudiesModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></FeaturedCaseStudiesModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteFeaturedInModule') {
          return (
            <FeaturedInModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></FeaturedInModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteFormModule') {
          return (
            <FormModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></FormModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteImageGridModule') {
          return (
            <ImageGridModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></ImageGridModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteInsightsCarouselModule') {
          return (
            <InsightsCarouselModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></InsightsCarouselModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteLogosModule') {
          return (
            <LogosModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
              pageTheme={pageTheme}
            ></LogosModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteNumbersGridModule') {
          return (
            <NumbersGridModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></NumbersGridModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteQuoteModule') {
          return (
            <QuoteModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></QuoteModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteReviewsModule') {
          return (
            <ReviewsModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></ReviewsModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteTeamGridModule') {
          return (
            <TeamGridModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></TeamGridModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteTestimonialsModule') {
          return (
            <TestimonialsModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></TestimonialsModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteTextAndMediaModule') {
          return (
            <TextAndMediaModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></TextAndMediaModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteVideoReportsModule') {
          return (
            <VideoReportsModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></VideoReportsModule>
          );
        }
        if (module.__typename === 'SanityHpWebsiteWordSliderModule') {
          return (
            <WordSliderModule
              key={moduleKey}
              className={styles.module}
              {...commonModuleProps}
              {...module}
            ></WordSliderModule>
          );
        }

        // @ts-ignore
        throw new Error('Got unexpected typename: ' + module.__typename);
      })}
    </>
  );
}

export default ModulesContent;
