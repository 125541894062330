import React from 'react';
import { ButtonLinkType } from '../../../graphql-fragments/ButtonLink';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import { PageDocument } from '../../../types/types';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import ButtonLink from '../ButtonLink';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import * as styles from './BooksModule.module.scss';

interface Book {
  title: string;
  description: string;
  mobileImage: SanityImageType;
  image: SanityImageType;
  ctaButton?: ButtonLinkType;
  bookPage?: PageDocument;
}

export interface BooksModuleProps {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  text: string;
  books: Array<Book>;
  className?: string;
}

export function getModuleBgColor(props: BooksModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function BooksModule(props: BooksModuleProps & CommonModuleProps): React.ReactElement {
  const { backgroundColor, title, text, books, className, moduleId, previousModuleBgColor } = props;

  function renderBook(book: Book, rowLayout?: boolean) {
    return (
      <div className={clsx(styles.bookContainer, rowLayout && styles.rowLayout)}>
        <div className={styles.imageContainer}>
          <Image
            image={book.mobileImage}
            alt={book.title + ' Book'}
            className={styles.bookMobileImage}
          />
          <Image
            image={book.image}
            alt={book.title + ' Book'}
            className={styles.bookDesktopImage}
          />
        </div>
        <div className={styles.textContainer}>
          <h3 className={styles.bookTitle}>{book.title}</h3>
          <p className={styles.bookDescription}>{book.description}</p>
          {(book.bookPage || book.ctaButton) && (
            <div className={styles.buttonsContainer}>
              {book.bookPage && (
                <ButtonLink
                  outlined
                  to={{ pageReference: book.bookPage }}
                  linkClassName={styles.buttonContainer}
                  className={styles.button}
                >
                  Read more
                </ButtonLink>
              )}
              {book.ctaButton && (
                <ButtonLink
                  to={book.ctaButton}
                  linkClassName={styles.buttonContainer}
                  modalFormButtonClassName={styles.buttonContainer}
                  className={styles.button}
                >
                  {book.ctaButton.title}
                </ButtonLink>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      title={title}
      text={text}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
    >
      {books.length > 2 ? (
        <div className={styles.booksContainer}>
          <div className={styles.firstBookContainer}>{renderBook(books[0])}</div>
          <div className={styles.otherBooksContainer}>
            <h3 className={styles.otherBooksTitle}>Other books</h3>
            <div className={clsx(styles.booksWrapper, styles.rowBooks)}>
              {books.slice(1).map((book, i) => (
                <React.Fragment key={i}>
                  {renderBook(book, true)}
                  <div className={clsx(styles.divider, styles.fullWidth)}></div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.booksWrapper}>
          {books.map((book, i) => (
            <React.Fragment key={i}>
              {renderBook(book)}
              <div className={styles.divider}></div>
            </React.Fragment>
          ))}
        </div>
      )}
    </ModuleLayout>
  );
}

export default BooksModule;
